//防抖
export function simpleDebounce(fn, delay = 100) {
  let timer = null; // 局部变量 setTimeout调用产生的唯一id
  // eslint-disable-next-line prettier/prettier
  return function() {
    let args = arguments; //arguments 参数？
    if (timer) {
      clearTimeout(timer); //用过id暂停setTimeout还未执行带
    }
    timer = setTimeout(() => {
      fn.apply(this, args); // apply 改变this的指向 this 指向哪？
    }, delay);
  };
}
//节流
export function throttle(func, wait) {
  let previous = 0;
  return function() {
    let now = Date.now();
    let context = this;
    let args = arguments;
    console.log("now", now - previous);
    console.log("previous", previous);
    // console.log("wait", wait);
    if (now - previous > wait) {
      console.log("调用");
      func.apply(context, args);
      previous = now;
    }
  };
}
