<template>
  <div class="body-content">
    <!-- <van-nav-bar title="首页" /> -->
    <div class="content">
      <div class="head">
        <van-image class="head-image" round :src="ybtLogo" />
      </div>
      <van-form>
        <div class="inputbox">
          <span class="welcomeText">亿万选择 保你无忧</span>
        </div>
      </van-form>
      <div class="body">
        <!-- 未登录 -->
        <van-form ref="form" v-if="token === null || token === ''">
          <van-field
            v-model="form.username"
            name="用户名"
            label="用户名"
            placeholder="请输入用户名"
            :rules="usernameRules"
          />
          <van-field
            v-model="form.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="请输入密码"
            :rules="passwordRules"
          />
          <div style="margin: 16px;">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="onSubmit"
              >登录</van-button
            >
          </div>
          <div class="reg">
            <div @click="toRegister">没有账号？立即注册</div>
          </div>
        </van-form>
        <!-- 已登录 -->
        <van-form v-else>
          <div style="margin: 16px;">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="onLogout"
              >退出登录</van-button
            >
          </div>
          <div style="margin: 16px;">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="toPerson"
              >个人中心</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, Button, Image, Toast } from "vant";
import { mapActions, mapGetters } from "vuex";
import { downloadHostJbb } from "@/environment";
import { simpleDebounce } from "@/utils/simpleDebounce";
export default {
  inject: ["reload"],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast
  },
  data() {
    return {
      imageProxy: require("@/assets/imageProxy.png"),
      touxiang: require("@/assets/touxiang.png"),
      ybtLogo: require("@/assets/ybtLogo.jpg"),
      form: {
        username: "",
        password: ""
      },
      usernameRules: [{ required: true }],
      passwordRules: [
        { required: true },
        {
          validator: value => {
            return value.length > 5;
          },
          message: "密码长度不小于6位",
          trigger: "onBlur"
        }
      ],
      num: null
    };
  },
  computed: {
    ...mapGetters("user", ["userDetails"]),
    token() {
      return localStorage.getItem("Authorization");
    },
    welcomeText() {
      if (this.userDetails.url !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.touxiang = `${downloadHostJbb}` + this.userDetails.url;
      }
      return this.userDetails?.username
        ? `欢迎您，${this.userDetails.username}`
        : "";
    }
  },

  watch: {},
  methods: {
    ...mapActions("user", ["login", "logout", "info", "loginJbb", "details"]),
    //防抖
    validatorPassword(val) {
      if (val.length > 5) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit: simpleDebounce(function() {
      this.$refs.form
        .validate()
        .then(() => {
          this.onlogin();
        })
        .catch(() => {
          console.log("提交失败！");
        });
    }, 1000),
    //登录SSO
    async onlogin() {
      await this.loginJbb({ ...this.form });
      const res = await this.info();
      const dataMember = await this.details(res.data.data.userId);
      localStorage.setItem("sid", dataMember.data.data.sid);
      this.$router.push("/mobile/product/list");
    },
    toRegister() {
      this.$router.push("/mobile/register");
    },
    //退出登录
    onLogout() {
      this.logout().then(() => {
        this.reload();
      });
    },
    toPerson() {
      this.$router.push({
        name: "mobile-product-list"
      });
    }
  }
};
</script>
<style scoped>
.title {
  /* border-radius: 15px; */
  size: 1px;
  height: 50px;
  line-height: 50px;
  background-color: #20a0ff;
  color: #fff;
  text-align: center;
}
.content {
  padding: 120px 0 0 0;
  margin: 0 auto;
}
/* .body-content {
  background: url("../../assets/pgy.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-size: 375px 667px;
  background-attachment: fixed;
} */
.reg {
  margin: 0 auto;
  text-align: center;
}
.head {
  margin: 0 auto;
  width: 70%;
}
.body {
  padding: 20px;
}
.head-image {
  margin: 0 auto;
  display: block; /*行内元素装换成块级元素 居中 */
}
.inputbox {
  text-align: center;
}
.welcomeText {
  font-size: 18px;
  color: rgb(0, 138, 58, 1);
  font-weight: bold;
  font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
}
</style>
